var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-menu-popup",
    {
      attrs: { right: !_vm.isMobile, left: _vm.isMobile },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function () {
            return [
              _c("v-btn", { attrs: { depressed: "" } }, [
                _c("div", { staticClass: "d-flex align-center" }, [
                  _c(
                    "div",
                    { staticClass: "textDark--text mew-body capitalize mr-2" },
                    [_vm._v(" " + _vm._s(_vm.selectedPath.name) + " ")]
                  ),
                  _c("div", { staticClass: "path mew-body capitalize" }, [
                    _vm._v(" " + _vm._s(_vm.selectedPath.value) + " "),
                  ]),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.showDerivationPath,
        callback: function ($$v) {
          _vm.showDerivationPath = $$v
        },
        expression: "showDerivationPath",
      },
    },
    [
      _c("mew-popup", {
        attrs: {
          title: _vm.showRemove ? "Remove path?" : "Remove all custom paths?",
          "max-width": "336",
          show: _vm.showRemove || _vm.showRemoveAll,
          "left-btn": {
            text: "Cancel",
            color: "grey",
            method: _vm.hideRemove,
          },
          "right-btn": {
            text: "Remove",
            color: "error",
            enabled: true,
            method: function () {
              return _vm.showRemove
                ? _vm.deletePath(_vm.selectedRemovePath)
                : _vm.deleteAllPaths()
            },
          },
          "hide-close-btn": "",
        },
      }),
      _c(
        "div",
        { staticClass: "pa-6 text-left", staticStyle: { width: "350px" } },
        [
          _c("div", { staticClass: "mew-heading-2 mb-4" }, [
            _vm._v("Select a derivation path"),
          ]),
          _c("mew-search", {
            staticClass: "mb-8",
            attrs: {
              value: _vm.searchValue,
              placeholder: "find a path",
              "is-compact": "",
              "is-filled": "",
            },
            on: { input: _vm.setSearch },
          }),
          !_vm.disableCustomPaths
            ? _c(
                "div",
                _vm._l(
                  _vm.filteredCustomPaths,
                  function (filteredCustomPath, idx) {
                    return _c(
                      "div",
                      {
                        key: filteredCustomPath.name + idx,
                        staticClass: "d-flex",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "mb-6 d-flex align-center justify-space-between cursor-pointer flex-grow-1",
                            on: {
                              click: function ($event) {
                                return _vm.setPath(filteredCustomPath)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("v-img", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    src: require("@/assets/images/currencies/eth.png"),
                                    contain: "",
                                    "max-height": "24px",
                                    "max-width": "24px",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    class: [
                                      filteredCustomPath.name ===
                                      _vm.selectedPath.name
                                        ? "greenPrimary--text"
                                        : "textDark--text",
                                    ],
                                  },
                                  [_vm._v(_vm._s(filteredCustomPath.name))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                class:
                                  filteredCustomPath.value ===
                                  _vm.selectedPath.value
                                    ? "greenPrimary--text"
                                    : "path",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(filteredCustomPath.value) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "pa-3 mb-7 ml-2",
                            attrs: { icon: "", small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.handleRemove(filteredCustomPath)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "textDark" } }, [
                              _vm._v("mdi-close"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                0
              )
            : _vm._e(),
          !_vm.disableCustomPaths &&
          _vm.filteredCustomPaths.length > 0 &&
          _vm.filteredPaths.length > 0
            ? _c("v-divider", { staticClass: "mb-6" })
            : _vm._e(),
          _vm._l(_vm.filteredPaths, function (filteredPath, idx) {
            return _c(
              "div",
              {
                key: filteredPath.name + idx,
                staticClass:
                  "mb-7 d-flex align-center justify-space-between cursor-pointer",
                on: {
                  click: function ($event) {
                    return _vm.setPath(filteredPath)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-img", {
                      staticClass: "mr-2",
                      attrs: {
                        src: require("@/assets/images/currencies/eth.png"),
                        contain: "",
                        "max-height": "24px",
                        "max-width": "24px",
                      },
                    }),
                    _c(
                      "span",
                      {
                        class: [
                          filteredPath.name === _vm.selectedPath.name
                            ? "greenPrimary--text"
                            : "textDark--text",
                        ],
                      },
                      [_vm._v(_vm._s(filteredPath.name))]
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    class:
                      filteredPath.value === _vm.selectedPath.value
                        ? "greenPrimary--text"
                        : "path",
                  },
                  [_vm._v(" " + _vm._s(filteredPath.value) + " ")]
                ),
              ]
            )
          }),
          !_vm.disableCustomPaths
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex align-center justify-space-between bottom-buttons pt-4",
                },
                [
                  _c(
                    "mew-button",
                    {
                      attrs: {
                        "btn-style": "transparent",
                        "btn-size": "small",
                        "color-theme": "textDark",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.toggleCustomField(true)
                        },
                      },
                    },
                    [
                      _vm._v(" Add path "),
                      _c("v-icon", [_vm._v("mdi-menu-down")]),
                    ],
                    1
                  ),
                  _vm.filteredCustomPaths.length > 0
                    ? _c(
                        "mew-button",
                        {
                          attrs: {
                            "btn-style": "transparent",
                            "btn-size": "small",
                            "color-theme": "redPrimary",
                          },
                          nativeOn: {
                            click: function ($event) {
                              _vm.showRemoveAll = true
                            },
                          },
                        },
                        [_vm._v(" Remove all custom ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "custom-field",
              class: _vm.showCustomField ? "open" : "",
            },
            [
              _c("mew-input", {
                ref: "aliasInput",
                staticClass: "mt-4",
                attrs: { label: "Alias", placeholder: "my custom path" },
                on: { input: _vm.setCustomAlias },
              }),
              _c("mew-input", {
                ref: "pathInput",
                attrs: { label: "Path", placeholder: "m/44’/1’/0’/0" },
                on: { input: _vm.setCustomPath },
              }),
              _c(
                "v-row",
                {
                  staticClass: "d-flex align-center",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-1", attrs: { cols: "6" } },
                    [
                      _c("mew-button", {
                        attrs: {
                          "has-full-width": "",
                          "btn-style": "outline",
                          title: "Cancel",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.toggleCustomField.apply(null, arguments)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-1", attrs: { cols: "6" } },
                    [
                      _c("mew-button", {
                        attrs: { "has-full-width": "", title: "Add path" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.saveCustomPath.apply(null, arguments)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { attrs: { id: "bottomList" } }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
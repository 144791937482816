var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _vm.paths.length > 0 && !_vm.hidePathSelection
        ? _c(
            "div",
            { staticClass: "text-right mb-3" },
            [
              _c("access-wallet-derivation-path", {
                attrs: {
                  "selected-path": _vm.selectedPath,
                  "passed-paths": _vm.paths,
                  "disable-custom-paths": _vm.disableCustomPaths,
                },
                on: { setPath: _vm.setPath },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("mew-expand-panel", {
        attrs: { "interactive-content": true, "panel-items": _vm.panelItems },
        scopedSlots: _vm._u([
          {
            key: "panelBody1",
            fn: function () {
              return [
                _c(
                  "v-radio-group",
                  {
                    model: {
                      value: _vm.selectedAddress,
                      callback: function ($$v) {
                        _vm.selectedAddress = $$v
                      },
                      expression: "selectedAddress",
                    },
                  },
                  [
                    _c(
                      "app-table",
                      {
                        attrs: {
                          "loader-count": 5,
                          background: "",
                          "full-width": "",
                          flat: "",
                          loading: _vm.isLoading,
                          "no-table-padding": _vm.isMobile,
                        },
                      },
                      [
                        _c("table", [
                          _c("thead", [
                            _c("tr", [
                              _c("td", {
                                staticStyle: {
                                  width: "55px",
                                  "padding-right": "0",
                                },
                              }),
                              !_vm.isMobile
                                ? _c("td", { staticStyle: { padding: "0" } })
                                : _vm._e(),
                              _c("td", [_vm._v("ADDRESS")]),
                              !_vm.isOfflineApp
                                ? _c("td", { staticClass: "text-right" }, [
                                    !_vm.isMobile
                                      ? _c("span", [
                                          _vm._v(_vm._s(_vm.network.type.name)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" BALANCE "),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.accounts, function (acc) {
                              return _c("tr", { key: acc.address }, [
                                _c(
                                  "td",
                                  { staticStyle: { "padding-right": "0" } },
                                  [
                                    _c("v-radio", {
                                      staticClass: "mb-0",
                                      attrs: { label: "", value: acc.address },
                                    }),
                                  ],
                                  1
                                ),
                                !_vm.isMobile
                                  ? _c(
                                      "td",
                                      { staticStyle: { padding: "0" } },
                                      [_vm._v(_vm._s(acc.idx))]
                                    )
                                  : _vm._e(),
                                _c("td", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center flex-shrink-1",
                                    },
                                    [
                                      _c("mew-blockie", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          width: "21px",
                                          height: "21px",
                                          address: acc.address,
                                        },
                                      }),
                                      _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "d-flex align-center",
                                          },
                                          [
                                            _c("mew-transform-hash", {
                                              staticStyle: {
                                                width: "34vw",
                                                "max-width": "220px",
                                                "min-width": "100px",
                                              },
                                              attrs: { hash: acc.address },
                                            }),
                                            !_vm.isMobile
                                              ? _c(
                                                  "app-copy-btn",
                                                  {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      "copy-value": acc.address,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          icon: "",
                                                          color: "greenPrimary",
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("@/assets/images/icons/icon-copy-green.svg"),
                                                            alt: "copy",
                                                            height: "13",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            !_vm.isMobile
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: _vm.getExplorerLink(
                                                        acc.address
                                                      ),
                                                      rel: "noopener noreferrer",
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          icon: "",
                                                          color: "greenPrimary",
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("@/assets/images/icons/icon-arrow-top-right-green.svg"),
                                                            alt: "copy",
                                                            height: "13",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        acc.ensName
                                          ? _c(
                                              "div",
                                              { staticClass: "mew-label" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(acc.ensName) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "d-flex justify-end align-center",
                                  },
                                  [
                                    !_vm.isOfflineApp
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "balance-overflow text-right mew-label",
                                            staticStyle: {
                                              width: "18vw",
                                              "max-width": "81px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(acc.balance) + " "
                                            ),
                                            !_vm.isMobile
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.network.type.name
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "pb-6",
                        attrs: { align: "center", justify: "center" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("mew-button", {
                              attrs: {
                                title: "Previous",
                                "color-theme": "basic",
                                icon: "mdi-chevron-left",
                                "icon-type": "mdi",
                                "btn-size": "small",
                                "icon-align": "left",
                                "btn-style": "transparent",
                                disabled:
                                  _vm.currentIdx === 0 || _vm.addressPage === 1,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.previousAddressSet.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            }),
                            _c("mew-button", {
                              attrs: {
                                title: "Next",
                                "color-theme": "basic",
                                icon: "mdi-chevron-right",
                                "icon-type": "mdi",
                                "btn-size": "small",
                                "icon-align": "right",
                                "btn-style": "transparent",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.nextAddressSet.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "panelBody2",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "px-5" },
                  [
                    _c("network-switch", {
                      attrs: { "is-wallet": false },
                      on: { newNetwork: _vm.setNetworkPanel },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "d-flex align-center flex-column py-6" },
        [
          _c("mew-checkbox", {
            staticClass: "justify-center",
            attrs: { label: _vm.label, link: _vm.link },
            model: {
              value: _vm.acceptTerms,
              callback: function ($$v) {
                _vm.acceptTerms = $$v
              },
              expression: "acceptTerms",
            },
          }),
        ],
        1
      ),
      _c("app-btn-row", {
        staticClass: "my-2",
        attrs: {
          "next-btn-text": "Access my wallet",
          "next-btn-method": _vm.accessWallet,
          "back-btn-method": null,
          "next-disable": _vm.buttonDisabled,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }